import {PagingSorting} from '../../helpers/contants';

import {UserSearchState} from './types';
import {UserSearchActions, UserSearchActionsType} from './actions';

export const userSearchInitialState: UserSearchState = {
    rows: [],
    filterText: '',
    activePage: 1,
    sortField: 'firstName',
    sortDirection: PagingSorting.ASC,
    total: 0,
    pageSize: 10,
    errorInfo: undefined,
};

export function userSearchReducers(state: UserSearchState = userSearchInitialState, action: UserSearchActionsType) {
    const {type, payload} = action;

    switch (type) {
        case UserSearchActions.SET_SEARCH_TEXT:
            return {
                ...state,
                filterText: payload,
            };
        case UserSearchActions.SET_CURRENT_PAGE:
            return {
                ...state,
                activePage: payload,
            };
        case UserSearchActions.SET_PAGE_SIZE:
            return {
                ...state,
                pageSize: payload,
            };
        case UserSearchActions.SET_SORTING:
            return {
                ...state,
                sortField: payload.sortField,
                sortDirection: payload.direction,
            };
        case UserSearchActions.SET_PAGINATION_RESULT_INFO:
            return {
                ...state,
                activePage: payload.page,
                total: payload.totalElements,
            };
        case UserSearchActions.SET_USER_SEARCH_RESULTS:
            return {
                ...state,
                rows: payload,
            };
        case UserSearchActions.SET_USER_SEARCH_ERROR_INFO:
            return {
                ...state,
                errorInfo: payload,
            };
        case UserSearchActions.RESET_STATE:
            return userSearchInitialState;
        default:
            return state;
    }
}
