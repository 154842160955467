import {ActionResult, PaginationResult, UserSearchListItemEntity} from './types';

export enum UserSearchActions {
    SET_SEARCH_TEXT = 'SET_SEARCH_TEXT',
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
    SET_PAGE_SIZE = 'SET_PAGE_SIZE',
    SET_SORTING = 'SET_SORTING',
    SET_PAGINATION_RESULT_INFO = 'SET_PAGINATION_RESULT_INFO',
    SET_USER_SEARCH_RESULTS = 'SET_USER_SEARCH_RESULTS',
    SET_USER_SEARCH_ERROR_INFO = 'SET_USER_SEARCH_ERROR_INFO',
    RESET_STATE = 'RESET_STATE',
}

export function setSearchText(filterText: string): ActionResult {
    return {
        type: UserSearchActions.SET_SEARCH_TEXT,
        payload: filterText,
    };
}

export function setCurrentPage(activePage: number) {
    return {
        type: UserSearchActions.SET_CURRENT_PAGE,
        payload: activePage,
    };
}

export function setPageSize(pageSize: number) {
    return {
        type: UserSearchActions.SET_PAGE_SIZE,
        payload: pageSize,
    };
}

export function setSorting(sortField: string, direction: string) {
    return {
        type: UserSearchActions.SET_SORTING,
        payload: {
            sortField,
            direction,
        },
    };
}

export function setPaginationResult(paginationResult: PaginationResult) {
    return {
        type: UserSearchActions.SET_PAGINATION_RESULT_INFO,
        payload: paginationResult,
    };
}

export function setUserSearchResults(records: Array<UserSearchListItemEntity>) {
    return {
        type: UserSearchActions.SET_USER_SEARCH_RESULTS,
        payload: records,
    };
}

export function setUserSearchErrorInfo(error: unknown) {
    return {
        type: UserSearchActions.SET_USER_SEARCH_ERROR_INFO,
        payload: error,
    };
}

export function resetState(): ActionResult {
    return {
        type: UserSearchActions.RESET_STATE,
    };
}

export type UserSearchActionsType =
    | ReturnType<typeof setSearchText>
    | ReturnType<typeof setCurrentPage>
    | ReturnType<typeof setPageSize>
    | ReturnType<typeof setSorting>
    | ReturnType<typeof setPaginationResult>
    | ReturnType<typeof setUserSearchResults>
    | ReturnType<typeof setUserSearchErrorInfo>
    | ReturnType<typeof resetState>;
