import thunk from 'redux-thunk';
import {Store, applyMiddleware, combineReducers, compose, createStore} from 'redux';

import {brandAssetReducer} from './brandAsset/reducers';
import {AppState} from './types';
import {userReducers} from './user/reducers';
import {UserActionsType} from './user/actions';
import {appConfigReducers} from './appConfig/reducers';
import {AppConfigActionsType} from './appConfig/actions';
import {AppConfigInfoDispatcher} from './appConfig/actionHandlers';
import {LinkedAccountsActionsType} from './linkedAccounts/actions';
import {linkedAccountsReducers} from './linkedAccounts/reducers';
import {FederationAccountsActionsType} from './accountFederation/actions';
import {federationAccountsReducers} from './accountFederation/reducers';
import {UserSearchActionsType} from './userSearch/actions';
import {userSearchReducers} from './userSearch/reducers';

const composeEnhancers: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = {
    userState: userReducers,
    appConfigState: appConfigReducers,
    linkedAccountsState: linkedAccountsReducers,
    federationAccountsState: federationAccountsReducers,
    userSearchState: userSearchReducers,
    brandAssetState: brandAssetReducer,
};

type AppActions =
    | UserActionsType
    | AppConfigActionsType
    | LinkedAccountsActionsType
    | FederationAccountsActionsType
    | UserSearchActionsType;

export const appStore: Store<AppState, AppActions> = createStore(
    combineReducers(reducers),
    {},
    composeEnhancers(applyMiddleware(thunk)),
);

export const dispatch = appStore.dispatch.bind(appStore) as AppConfigInfoDispatcher;
