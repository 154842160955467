import {Cookies} from '@ringcentral/web-modules-core';
import {parse as parseQuery} from 'query-string';

import {AppConfig} from '../redux/types';

export const DEFAULT_LOCALE = 'en_US';
const PSEUDO_LOCALE = 'en_ZZ';

const USER_LANGUAGE_COOKIE_NAME = 'localeId';

export const i18n = {
    initFromBrandInfo(res: AppConfig) {
        this.setBrandLanguage(this.normalizeLocaleName(res.localeCode));
        this.setSupportedLocaleCodes(res.supportedLocaleCodes);
    },

    _brandLanguage: DEFAULT_LOCALE,
    getBrandLanguage() {
        return this._brandLanguage;
    },
    setBrandLanguage(locale: string) {
        this._brandLanguage = locale;
    },

    getQueryLanguage(): string {
        return parseQuery(window.location.search)['localeId'] as string;
    },
    getCookieLanguage() {
        return Cookies.get(USER_LANGUAGE_COOKIE_NAME) as string;
    },

    /** @private */
    localeCode: DEFAULT_LOCALE,
    setCookieLanguage(localeCode: string) {
        Cookies.set(USER_LANGUAGE_COOKIE_NAME, localeCode);
    },

    getCurrentLanguage() {
        const queryLanguage = this.getQueryLanguage();
        const cookieLanguage = this.getCookieLanguage();
        const preferLanguage = queryLanguage || cookieLanguage;
        if (this.isValidLanguage(preferLanguage)) {
            this.setCookieLanguage(preferLanguage);
            return preferLanguage;
        }
        const brandLanguage = this.getBrandLanguage();
        this.setCookieLanguage(brandLanguage);
        return brandLanguage;
    },

    isValidLanguage(locale: string) {
        if (this.normalizeLocaleName(locale) === PSEUDO_LOCALE) {
            return true;
        }

        return this.getSupportedLocaleCodes().some(
            (o) => this.normalizeLocaleName(o) === this.normalizeLocaleName(locale),
        );
    },

    /** @private */
    supportedLocaleCodes: [],
    setSupportedLocaleCodes(supportedLocaleCodes: any) {
        this.supportedLocaleCodes = supportedLocaleCodes;
    },
    getSupportedLocaleCodes() {
        return this.supportedLocaleCodes;
    },

    normalizeLocaleName(locale: string) {
        if (!locale) {
            return locale;
        }
        return locale.replace('-', '_');
    },
};
