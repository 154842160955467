import {parse as parseQuery} from 'query-string';

import {notify} from './helpers/loginWindowsNotification';
import {isDebugMode} from './helpers/utils';
import {RouteConstants} from './routes/RouteConstants';

export function bootstrapCallback(search = document.location.search) {
    const data = parseQuery(search);

    notify({
        data,
        from: 'maa',
        messageId: Date.now(),
    });

    if (!isDebugMode()) {
        if (window.opener) {
            window.close();
        } else {
            window.location.href = RouteConstants.index;
        }
    }
}
