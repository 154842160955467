import {MergedBrandAssetData} from '../../data/types';

export enum BrandAssetActions {
    INIT_BRAND_ASSET = 'INIT_BRAND_ASSET',
}

export function initBrandAsset(brandAssetData: MergedBrandAssetData) {
    return {
        type: BrandAssetActions.INIT_BRAND_ASSET,
        payload: brandAssetData,
    };
}

export type BrandAssetActionsType = ReturnType<typeof initBrandAsset>;
