import {EventBus, _} from '@ringcentral/web-modules-core';

export const NOTIFICATION_KEY = '$$__NOTIFY_KEY__$$__DONT_USE_THIS_NAME';

export type SubScribeCallBackArgs = {
    data: {
        type?: string;
        id?: string;
        error?: string;
    };
    from: 'maa';
    messageId: number;
};

export function notify(data: SubScribeCallBackArgs) {
    const json = JSON.stringify(data);
    window.localStorage.setItem(NOTIFICATION_KEY, json);
    EventBus.emit(NOTIFICATION_KEY, data);
}

export const handleStorageChangeForNotification = (callback: (data: any) => void) => (event: any) => {
    const data = getNotifyData(event);
    if (data) {
        callback(data);
        window.localStorage.removeItem(NOTIFICATION_KEY);
    }
};

export function subscribe(callbackFn: (data: SubScribeCallBackArgs) => void) {
    const callback = _.once(callbackFn);
    window.addEventListener('storage', handleStorageChangeForNotification(callback));
    EventBus.on(NOTIFICATION_KEY, callback);

    return () => {
        EventBus.off(NOTIFICATION_KEY, callback);
        window.removeEventListener('storage', callback as any);
    };
}

export function getNotifyData(event: any) {
    if (event.key === NOTIFICATION_KEY) {
        const message = event.newValue;
        if (typeof message === 'string') {
            try {
                const data = JSON.parse(message);
                if (data.from === 'maa') {
                    return data;
                }
            } catch (E) {
                // eslint-disable-next-line no-console
                console.warn(E);
            }
        }
    }

    return null;
}
