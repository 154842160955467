import {getBSSJSON, getBSSJsonLink, mergeBSSResponses} from '../helpers/brandAssetBL';
import {BASE_BSS_JSON_FILE_NAME} from '../helpers/contants';
import {DEFAULT_LOCALE, i18n} from '../helpers/i18n';

export async function getBSSInfo() {
    const baseJSONlink = getBSSJsonLink(BASE_BSS_JSON_FILE_NAME);
    const langJSONlink = getBSSJsonLink(i18n.getCurrentLanguage());

    const baseResponse = await getBSSJSON(baseJSONlink);

    let langResponse;
    try {
        langResponse = await getBSSJSON(langJSONlink);
    } catch (error) {
        const defaultLangJSONlink = getBSSJsonLink(DEFAULT_LOCALE);
        langResponse = await getBSSJSON(defaultLangJSONlink);
    }

    return mergeBSSResponses(baseResponse, langResponse);
}
