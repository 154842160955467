import {getConfig, setCSS3Variables} from '@ringcentral/web-modules-core';

import {logError} from '../data/Transport';
import {BrandAssetData, BrandAssetThemeType, MergedBrandAssetData} from '../data/types';
import {currentThemeSelector, faviconSelector} from '../redux/brandAsset/selectors';

interface BrandAsset {
    baseUri: string;
    assetId: string;
}

export function getBrandAsset(): BrandAsset {
    return getConfig().brandAsset || {};
}

export function setFaviconAndCSS3Variables(mergedBrandAssetData: MergedBrandAssetData) {
    setFavicon(faviconSelector(mergedBrandAssetData));
    setCSS3Variables(currentThemeSelector(mergedBrandAssetData));
}

export function setFavicon(favicon: string) {
    const href = getBrandAssetImage(favicon);
    const link = document.createElement('link');
    link.href = href;
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    document.head.appendChild(link);
}

export function getBrandAssetImage(imagePath: string | undefined) {
    const {baseUri, assetId} = getBrandAsset();
    return assetId ? `${baseUri}/${assetId}${imagePath}` : '';
}

export function getLaunchToNow(launchYear: string | undefined) {
    const currentYear = new Date().getFullYear();

    return launchYear ? `${launchYear}-${currentYear}` : currentYear;
}

export function getBSSJsonLink(jsonName: string) {
    const {baseUri, assetId} = getBrandAsset();
    return `${baseUri}/${assetId}/${jsonName.replace('_', '-')}.json`;
}

export function mergeBSSResponses(baseJSON: BrandAssetData, langJSON: BrandAssetData): MergedBrandAssetData {
    const {themes: baseThemes, ...restBaseJSON} = baseJSON;
    const {themes: langThemes, ...restLangJSON} = langJSON;
    if (!baseThemes) {
        return {};
    }

    const themeDict: Record<string, BrandAssetThemeType> = baseThemes.reduce(
        (acc, theme) => ({
            ...acc,
            [theme.themeId]: theme,
        }),
        {},
    );

    langThemes?.forEach((theme) => {
        themeDict[theme.themeId] = {
            ...themeDict[theme.themeId],
            ...theme,
        };
    });

    return {
        ...restBaseJSON,
        ...restLangJSON,
        themeDict,
        //Currently, maa doesn't support multiple themes, we just use the default one.
        currentThemeId: baseThemes[0].themeId,
    };
}

export async function getBSSJSON(link: string): Promise<BrandAssetData> {
    return fetch(link)
        .then((res) => res.json())
        .catch((error) => {
            logError({
                url: link,
                error,
            });

            throw error;
        });
}
