export const BRAND_ID_RC = 1210;
export const BRAND_ID_RC_EU = 2010;
export const BRAND_ID_ATT = 3420;
export const BRAND_ID_ATT_UB = 3460; // AT&T Universal Biller brand
export const BRAND_ID_CA = 3610;
export const BRAND_ID_UK = 3710;
export const BRAND_ID_AU = 5010;
export const BRAND_ID_TELUS = 7310;
export const BRAND_ID_BT = 7710;
export const BRAND_ID_RAINBOW = 2110;
export const BRAND_ID_VERIZON = 2210;
export const BRAND_ID_AVAYA = 6010;
export const BRAND_ID_FRONTIER = 4910;
export const BRAND_ID_CHARTER_ENTERPRISE = 5210;
export const BRAND_ID_CHARTER_SMB = 5110;
export const BRAND_ID_FEDRAMP = 1250;
