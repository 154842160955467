import {FederationAccount} from '../types';

export enum FederationAccountsActions {
    SET_FEDERATION_ACCOUNTS_INFO = 'SET_FEDERATION_ACCOUNTS_INFO',
    SET_FEDERATION_ACCOUNTS_ERROR_INFO = 'SET_FEDERATION_ACCOUNTS_ERROR_INFO',
}

export function setFederationAccountsInfo(federationAccounts: Array<FederationAccount>) {
    return {
        type: FederationAccountsActions.SET_FEDERATION_ACCOUNTS_INFO,
        payload: federationAccounts,
    };
}

export function setFederationAccountsErrorInfo(error: unknown) {
    return {
        type: FederationAccountsActions.SET_FEDERATION_ACCOUNTS_ERROR_INFO,
        payload: error,
    };
}

export type FederationAccountsActionsType =
    | ReturnType<typeof setFederationAccountsInfo>
    | ReturnType<typeof setFederationAccountsErrorInfo>;
