import {Dispatch} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {getAppConfigInfo} from '../../data/Transport';
import {AppConfigState} from '../types';

import {AppConfigActionsType, setAppConfigErrorInfo, setAppConfigInfo} from './actions';

export type AppConfigInfoDispatcher = ThunkDispatch<AppConfigState, void, AppConfigActionsType>;

export function fetchAppConfigInfo(data: {s: string}) {
    return async (dispatch: Dispatch<AppConfigActionsType>) => {
        try {
            const appConfigInfo = await getAppConfigInfo(data);

            dispatch(setAppConfigInfo(appConfigInfo));
            dispatch(setAppConfigErrorInfo(null));

            return appConfigInfo;
        } catch (error) {
            dispatch(setAppConfigErrorInfo(error));

            throw error;
        }
    };
}
