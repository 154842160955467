import {AppConfig} from '../types';

export enum AppConfigActions {
    SET_APP_CONFIG_INFO = 'SET_APP_CONFIG_INFO',
    SET_APP_CONFIG_ERROR_INFO = 'SET_APP_CONFIG_ERROR_INFO',
}

export function setAppConfigInfo(appConfig: AppConfig) {
    return {
        type: AppConfigActions.SET_APP_CONFIG_INFO,
        payload: appConfig,
    };
}

export function setAppConfigErrorInfo(error: unknown) {
    return {
        type: AppConfigActions.SET_APP_CONFIG_ERROR_INFO,
        payload: error,
    };
}

export type AppConfigActionsType = ReturnType<typeof setAppConfigInfo> | ReturnType<typeof setAppConfigErrorInfo>;
