import {createSelector} from 'reselect';

import {BrandAssetThemeType, MergedBrandAssetData} from '../../data/types';

export const themeDictSelector = (state: MergedBrandAssetData) => state.themeDict || {};
export const currentThemeIdSelector = (state: MergedBrandAssetData) => state.currentThemeId || '';
export const currentThemeSelector = createSelector(
    [themeDictSelector, currentThemeIdSelector],
    (themeDict, currentThemeId) => themeDict[currentThemeId] || {},
);

const createThemePropSelector = (prop: keyof BrandAssetThemeType) =>
    createSelector([currentThemeSelector], (theme) => theme[prop]);

export const serviceSiteMainLogoSelector = createThemePropSelector('serviceSiteMainLogo');
export const partnershipLabelLogoSelector = createThemePropSelector('partnershipLabelLogo');
export const faviconSelector = createThemePropSelector('favicon');

export const launchYearSelector = (state: MergedBrandAssetData) => state.launchYear || '';
export const displayNameSelector = (state: MergedBrandAssetData) => state.displayName || '';
export const copyrightSelector = (state: MergedBrandAssetData) => state.copyright || '';
export const notificationTradeMarkSelector = (state: MergedBrandAssetData) => state.notificationTradeMark || '';
export const privacyLinkSelector = (state: MergedBrandAssetData) => state.privacyPolicyPage || '';
export const feedbackLinkSelector = (state: MergedBrandAssetData) => state.feedbackPage || '';
export const legalLinkSelector = (state: MergedBrandAssetData) => state.legalInfoPage || '';
export const tosLinkSelector = (state: MergedBrandAssetData) => state.termsOfServicePage || '';
