import {User} from '../types';

export enum UserActions {
    SET_USER_INFO = 'SET_USER_INFO',
    SET_USER_ERROR_INFO = 'SET_USER_ERROR_INFO',
    SET_LOADING_STATUS_INFO = 'SET_LOADING_STATUS_INFO',
}

export function setUserInfo(user: User) {
    return {
        type: UserActions.SET_USER_INFO,
        payload: user,
    };
}

export function setUserErrorInfo(error: unknown) {
    return {
        type: UserActions.SET_USER_ERROR_INFO,
        payload: error,
    };
}

export function setLoadingStatusInfo(error: unknown) {
    return {
        type: UserActions.SET_LOADING_STATUS_INFO,
        payload: error,
    };
}

export type UserActionsType =
    | ReturnType<typeof setUserInfo>
    | ReturnType<typeof setUserErrorInfo>
    | ReturnType<typeof setLoadingStatusInfo>;
