/**
 * ATTENTION!!!
 * THIS FILE IS AUTOMATICALLY GENERATED BY WEBPACK BUILD SCRIPT
 * DO NOT CHANGE IT MANUALLY
 * ALL CHANGES WILL BE OVERWRITTEN
 * @return {Promise}
 */
export default function loadStyle(brand) {
    switch (brand) {
        default:
            return import("./_styles.scss" /* webpackChunkName: "styles" */);
    }
}