export default {
    PAGE_TITLE: 'Account Federation',
    HELP_TEXT: 'Below is a list of your federated accounts linked to your primary account {mainAccount}',
    COMPANY_NAME: 'Company Name',
    MAIN_NUMBER: 'Main Number',
    FEDERATED_ACCOUNT: 'Federated Account:',
    PRIMARY_ACCOUNT: 'Primary Account:',
    PRIMARY_ACCOUNT_HELP_TEXT:
        'The primary account has you listed as a user so that you can apply changes to your settings.',
    OPEN_ACCOUNT_ERROR: {
        '0': 'Unable to open account due to an account setup error. Contact support via {supportNumber}.',
        '2010':
            'Unable to open account due to an account setup error. Please <a class="show-underline" target="_blank" href="{callSupportURL}">contact support</a>.',
        '2110':
            'Unable to open account due to an account setup error. Please <a class="show-underline" target="_blank" href="{callSupportURL}">contact support</a>.',
        '2210':
            'Unable to open account due to an account setup error. Please <a class="show-underline" target="_blank" href="{callSupportURL}">contact support</a>.',
        '6010':
            'Unable to open account due to an account setup error. Please <a class="show-underline" target="_blank" href="{callSupportURL}">contact support</a>.',
        '2020':
            'Unable to open account due to an account setup error. Please <a class="show-underline" target="_blank" href="{callSupportURL}">contact support</a>.',
    },
    UNABLE_TO_OPEN_ACCOUNT: 'Unable to Open Account',
};
