import {MergedBrandAssetData} from '../../data/types';

import {BrandAssetActions} from './actions';

const brandAssetInitialState: MergedBrandAssetData = {};

export const brandAssetReducer: (
    state: MergedBrandAssetData,
    action: {type: BrandAssetActions; payload: MergedBrandAssetData},
) => MergedBrandAssetData = (state = brandAssetInitialState, action) => {
    switch (action.type) {
        case BrandAssetActions.INIT_BRAND_ASSET: {
            return action.payload;
        }
        default:
            return state;
    }
};
