import {
    EventBus,
    GlobalLoader,
    React,
    ReactDOM,
    constants as WmcConstants,
    createStringsLoader,
    loadSkin,
    moment,
    setConfig,
    setSkinLoader,
    showAlert,
    translate,
} from '@ringcentral/web-modules-core';
import {Provider} from 'react-redux';

import {setFaviconAndCSS3Variables} from './helpers/brandAssetBL';
import {fetchBrandAssetData} from './redux/brandAsset/actionHandlers';
import skinLoader from './skin/loader';
import {defaultStrings, loader} from './lang/loader';
import {appStore, dispatch} from './redux/appStore';
import {fetchAppConfigInfo} from './redux/appConfig/actionHandlers';
import {getLocalizedError, getUBrandId} from './helpers/utils';
import {i18n} from './helpers/i18n';
import {loadLanguage} from './lang/langLoader';
import langCommon from './lang/common/index-en_US';
import {LOGIN_REQUIRED, ON_REQUEST_ERROR} from './helpers/contants';
import {RouteConstants} from './routes/RouteConstants';
import {initTestAutomation} from './helpers/initTestAutomation';
import {AppConfig} from './redux/types';

setSkinLoader(skinLoader);
createStringsLoader({loader, defaultStrings, moment});

export function initGlobalLoader(text: string, extraProps = {}, id = 'global-loader-container', node = document.body) {
    if (document.getElementById(id)) {
        return;
    }

    const showEvents = [
        WmcConstants.eventBeforeAjaxRequest,
        WmcConstants.eventShowGlobalLoader,
        WmcConstants.eventBeforeStoreReload,
    ];

    const hideEvents = [
        WmcConstants.eventAfterAjaxRequest,
        WmcConstants.eventHideGlobalLoader,
        WmcConstants.eventAfterStoreReload,
    ];

    const globalLoaderContainer = document.createElement('div');
    globalLoaderContainer.id = id;
    globalLoaderContainer.className = 'bootstrap-rc';
    node.appendChild(globalLoaderContainer);

    ReactDOM.render(
        React.createElement(GlobalLoader, {showEvents, hideEvents, ...extraProps}, text),
        globalLoaderContainer,
    );
}

export const hideHTMLLoading = () => {
    const loaderElement = document.getElementById('loader');
    if (loaderElement) {
        loaderElement.style.display = 'none';
    }
};

export const mountApp = async (node: HTMLElement | null) => {
    const {Application} = await import('./Application');

    ReactDOM.render(
        <Provider store={appStore}>
            <Application />
        </Provider>,
        node,
    );
};

export const initAppConfigInfo = async (config: AppConfig) => {
    const {brandId, ...otherConfig} = config;
    await loadLanguage(config.localeCode);
    i18n.initFromBrandInfo(config);
    await loadLanguage(i18n.getCurrentLanguage());
    setConfig({
        ...otherConfig,
        brandId: Number(brandId),
    });

    await loadSkin(`${config.brandId}`);
};

export const OnRequestError = ({error}: {error: string | object}) => {
    const isLoginRequired = error === LOGIN_REQUIRED;
    const isLoginPage = window.location.pathname.startsWith(RouteConstants.login);
    const isIndexPage = window.location.pathname === RouteConstants.index;

    if (isLoginRequired && !isLoginPage && !isIndexPage) {
        window.location.pathname = RouteConstants.login;
        return;
    }

    if (isLoginRequired && (isLoginPage || isIndexPage)) {
        return;
    }

    showAlert({
        testAutomationId: 'showRequestErrorMessage',
        messageText: getLocalizedError(error),
    });
};

export const bootstrapApplication = async () => {
    initTestAutomation();
    initGlobalLoader(translate(langCommon.LOADING));
    EventBus.on(ON_REQUEST_ERROR, OnRequestError);
    const config = await dispatch(fetchAppConfigInfo({s: getUBrandId()}));
    await initAppConfigInfo(config);

    const mergedBrandAssetData = await dispatch(fetchBrandAssetData());
    setFaviconAndCSS3Variables(mergedBrandAssetData);

    hideHTMLLoading();
    await mountApp(document.querySelector('#app'));
};
