/**
 * ATTENTION!!!
 * THIS FILE IS AUTOMATICALLY GENERATED BY WEBPACK BUILD SCRIPT
 * DO NOT CHANGE IT MANUALLY
 * ALL CHANGES WILL BE OVERWRITTEN
 * eslint-disable
 */
// static import to make sure everything is bound correctly
import * as defaultStrings from './en_US';
export {defaultStrings};
export const loader = async locale => {
    if (locale === 'de_DE') return await import(/* webpackChunkName:'de_DE' */ './de_DE');
    if (locale === 'en_AU') return await import(/* webpackChunkName:'en_AU' */ './en_AU');
    if (locale === 'en_CA') return await import(/* webpackChunkName:'en_CA' */ './en_CA');
    if (locale === 'en_GB') return await import(/* webpackChunkName:'en_GB' */ './en_GB');
    if (locale === 'en_ZZ') return await import(/* webpackChunkName:'en_ZZ' */ './en_ZZ');
    if (locale === 'es_419') return await import(/* webpackChunkName:'es_419' */ './es_419');
    if (locale === 'es_ES') return await import(/* webpackChunkName:'es_ES' */ './es_ES');
    if (locale === 'fi_FI') return await import(/* webpackChunkName:'fi_FI' */ './fi_FI');
    if (locale === 'fr_CA') return await import(/* webpackChunkName:'fr_CA' */ './fr_CA');
    if (locale === 'fr_FR') return await import(/* webpackChunkName:'fr_FR' */ './fr_FR');
    if (locale === 'it_IT') return await import(/* webpackChunkName:'it_IT' */ './it_IT');
    if (locale === 'ja_JP') return await import(/* webpackChunkName:'ja_JP' */ './ja_JP');
    if (locale === 'ko_KR') return await import(/* webpackChunkName:'ko_KR' */ './ko_KR');
    if (locale === 'nl_NL') return await import(/* webpackChunkName:'nl_NL' */ './nl_NL');
    if (locale === 'pl_PL') return await import(/* webpackChunkName:'pl_PL' */ './pl_PL');
    if (locale === 'pt_BR') return await import(/* webpackChunkName:'pt_BR' */ './pt_BR');
    if (locale === 'pt_PT') return await import(/* webpackChunkName:'pt_PT' */ './pt_PT');
    if (locale === 'sv_SE') return await import(/* webpackChunkName:'sv_SE' */ './sv_SE');
    if (locale === 'zh_CN') return await import(/* webpackChunkName:'zh_CN' */ './zh_CN');
    if (locale === 'zh_HK') return await import(/* webpackChunkName:'zh_HK' */ './zh_HK');
    if (locale === 'zh_TW') return await import(/* webpackChunkName:'zh_TW' */ './zh_TW');
    return null; // loader must return null for default (en_US) locale
};


/** @returns {Array<String>} */
export const getSupportedLocales = () => [
    'en_US',
    'de_DE',
    'en_AU',
    'en_CA',
    'en_GB',
    'en_ZZ',
    'es_419',
    'es_ES',
    'fi_FI',
    'fr_CA',
    'fr_FR',
    'it_IT',
    'ja_JP',
    'ko_KR',
    'nl_NL',
    'pl_PL',
    'pt_BR',
    'pt_PT',
    'sv_SE',
    'zh_CN',
    'zh_HK',
    'zh_TW'
];