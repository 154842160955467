export default {
    LOADING: 'loading...',
    BRAND_NAME: {
        '0': 'RingCentral',
        '2110': 'Rainbow Office',
        '2210': 'RingCentral with Verizon',
        '3420': 'AT&T Office@Hand',
        '4910': 'Frontier plus RingCentral',
        '6010': 'Avaya Cloud Office',
        '7310': 'TELUS Business Connect',
        '7710': 'BT Cloud Work',
    },
    APPLICATION_NAME: 'Multi-Account Access',
    APPLICATION_PORTAL_NAME: 'Accounts Management Portal',
    LOGOUT: 'Logout',
    PLEASE_LOGIN: 'Please login',
    LOGIN: 'Login',
    OPEN: 'Open',
    UNLINK: 'Unlink',
    MANAGE_LINKED_ACCOUNTS: 'Manage Linked Accounts',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    OK: 'OK',
    NO_ADMIN_USER: 'Please login with admin account credentials',
    LOGIN_REQUIRED: 'Login Required',
    INTERNAL_ERROR: 'Internal Error',
    TOKEN_EXPIRED: 'Token Expired',
    BAD_PARAMETER: 'Bad Parameter',
    ALERT: 'Alert',
    CONFIRM: 'Confirm',
    SEARCH: 'Search',
    TOTAL: 'Total',
    SHOW: 'Show',
    OPERATION: 'Operation',
    LOGIN_TO_THIS_ACCOUNT_TIP: 'login to this account',
    ACCOUNT_NAME: 'Account Name',
    EXT: 'Ext.',
    SITE: 'Site',
    SHOW_MORE: '{amount} more',
    SHOW_LESS: 'Show Less',
    LINKED_ACCOUNTS: 'Linked Accounts',
    ACCOUNT_FEDERATION: 'Account Federation',
    USER_SEARCH: 'User Search',
    OPEN_ACCOUNT: 'Open Account',
    OPEN_USER_DETAIL: 'Open User Detail',
    FOOTER_TEXTS: {
        LEGAL: 'Legal',
        PRIVACY: 'Privacy',
        RINGCENTRAL_TRADEMARK: 'All other logos, marks, designs and otherwise are the trademarks and intellectual property of RingCentral, Inc.',
        END_USER_LICENSE_AGREEMENT: {
            '0': '',
            '3420': 'End User License Agreement',
        },
        AND_RingCentral: {
            '0': '',
            '3420': "and RingCentral's",
        },
        SERVICE_TERMS: {
            '0': '',
            '7310': 'Service Terms',
        },
        CONTACT: {
            '0': '',
            '7710': 'Contact BT',
        },
        TERMS_OF_USE: {
            '0': '',
            '7710': 'Terms of use',
        },
        CODES_OF_PRACTICE: {
            '0': '',
            '7710': 'Codes of practice',
        },
        COMPLAINTS_CODE: {
            '0': '',
            '7710': 'Complaints',
        },
        T_CS: {
            '0': '',
            '7710': 'T&Cs',
        },
        FEEDBACK: {
            '0': '',
            '7710': 'Feedback',
        },
        BT_TRADE_MARK: {
            '0': '',
            '7710':
                'The BT Corporate Mark is a registered trade mark of British Telecommunications plc. BT Cloud Phone is a trade mark of British Telecommunications plc.',
        },
        ATT_ACKNOWLEDGE_AGREEMENT_TO_TEXT: 'By subscribing to and/or using AT&T Office@Hand, you acknowledge agreement to the AT&T\'s <a data-test-automation-id="tosLink" target="_blank" rel="noreferrer" href="{tosLink}">Terms of Service</a>. <a data-test-automation-id="ccpaLink" target="_blank" href="https://about.att.com/csr/home/privacy/rights_choices.html">Do Not Sell My Personal Information</a>'
    },
    EMPTY_GRID: 'No results',
    LANGUAGES: {
        en_CA: 'English (Canada)',
        en_GB: 'English (U.K.)',
        en_US: 'English (U.S.)',
        en_AU: 'English (Australia)',
        fr_CA: 'Français (Canada)',
        fr_FR: 'Français',
        de_DE: 'Deutsch',
        ru: 'Русский (Россия)',
        en_ZZ: 'RC Pseudo Locale',
        zh_CN: '简体中文',
        zh_HK: '繁體中文 (香港)',
        zh_TW: '繁體中文',
        es_ES: 'Español',
        it_IT: 'Italiano',
        es_419: 'Español (Latinoamérica)',
        pt_BR: 'Português (Brasil)',
        ja_JP: '日本語',
        nl_NL: 'Nederlands (Nederland)',
        ko_KR: '한국어 (대한민국)',
        pt_PT: 'Português (Portugal)',
        fi_FI: 'Suomi (Suomi)',
    },
    PAGE_NOT_FOUND: 'Page not found.',
    PAGE_UNAVAILABLE: 'The page you are looking for is temporarily unavailable.',
};
