import {MergedBrandAssetData} from '../data/types';

import {UserSearchState} from './userSearch/types';

export enum LoadingStatus {
    INITIAL = 'INITIAL',
    LOADING = 'LOADING',
    FINISHED = 'FINISHED',
}

export type Identifier = number | string;

export interface Status {
    error?: string | null;
    success: boolean;
}

export interface User {
    accountId: number;
    brandId: string;
    callSupportURL: string;
    callingCode: string;
    email: string;
    federated: boolean;
    homeCountryId: number;
    identity: string;
    loginType: string;
    mailboxId: number;
    newFederationUI: boolean;
    supportNumber: string;
    userId: string;
    userName: string;
}

export interface UserState {
    error?: unknown;
    user: User | {};
    loadingStatus: LoadingStatus;
}

export interface Pagination {
    currentPage: number;
    size: number;
}

export interface PaginationResult {
    totalElements: number;
    totalPages: number;
}

export interface SortField {
    key: string;
    direction: number;
}

export interface LinkedAccount {
    accountName: string;
    brandId: string;
    extension: string;
    formattedNumber: string;
    linkExpire: string;
    mailBoxId: string;
    ownerName: string;
    podId: number;
    _id: string;
}

export interface LinkedAccountsState {
    error?: string | null;
    search: string;
    pager: Pagination;
    links: Array<LinkedAccount>;
    serviceStatusReady?: boolean;
    statusError?: string | null;
    sortField: SortField | {};
    searchableField: Array<string>;
    openingAccount: Record<Identifier, boolean>;
    rowErrors: Record<Identifier, boolean>;
}

export interface LinkedAccountEditState {
    status: Status;
}

export interface LinkedAccountOpenState {
    status: Status;
    link: string;
}

export interface MainNumber {
    phoneNumber: string;
    formattedPhoneNumber: string;
}

export interface FederationAccount {
    companyName: string;
    federatedName: string;
    id: string;
    linkCreationTime: string;
    mainNumber: MainNumber;
}

export interface FederationAccountsState {
    error?: unknown;
    accounts: Array<FederationAccount>;
}

export interface AppConfig {
    brandId: string;
    localeCode: string;
    supportedLocaleCodes: Array<string>;
    legalUri: string;
    eulaUri: string;
    privacyPolicyUri: string;
}

export interface AppConfigState {
    error?: unknown;
    appConfig: AppConfig | {};
}

export interface PhoneNumber {
    phoneNumber: string;
    phoneType: string | null;
    usageType: string;
}

export interface Account {
    federated: boolean;
    id: number;
    mainNumber: string | null;
    serviceInfo: unknown;
}

export interface OperationAccount {
    email: string;
    extensionNumber: string;
    firstName: string;
    id: string;
    jobTitle: string;
    lastName: string;
    phoneNumber: Array<PhoneNumber>;
    site: string | null;
    status: string;
    type: string;
    account: Account;
}

export interface OperationAccountParams {
    text: string;
    perPage: number;
    page: number;
    sort: string;
    [key: string]: unknown;
}

export interface OperationAccountsState {
    error?: unknown;
    search: string;
    pager: Pagination;
    pagerResult: PaginationResult;
    records: Array<OperationAccount>;
    sortField: SortField | {};
}
export interface AppState {
    userState: UserState;
    appConfigState: AppConfigState;
    linkedAccountsState: LinkedAccountsState;
    federationAccountsState: FederationAccountsState;
    userSearchState: UserSearchState;
    brandAssetState: MergedBrandAssetData;
}
