import {AppConfigState} from '../types';

import {AppConfigActions, AppConfigActionsType} from './actions';

const appConfigInitialState: AppConfigState = {
    appConfig: {},
};

export function appConfigReducers(state: AppConfigState = appConfigInitialState, action: AppConfigActionsType) {
    const {type, payload} = action;

    switch (type) {
        case AppConfigActions.SET_APP_CONFIG_INFO:
            return {
                ...state,
                appConfig: payload,
            };
        case AppConfigActions.SET_APP_CONFIG_ERROR_INFO:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
}
