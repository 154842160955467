import {LinkedAccount} from '../types';

export enum LinkedAccountsActions {
    SET_LINKED_ACCOUNTS_INFO = 'SET_LINKED_ACCOUNTS_INFO',
    SET_LINKED_ACCOUNTS_ERROR_INFO = 'SET_LINKED_ACCOUNTS_ERROR_INFO',
    SET_ROW_ERRORS = 'SET_ROW_ERRORS',
    SET_LINK_EXPIRE = 'SET_LINK_EXPIRE',
    UPDATE_ACCOUNT_NAME = 'UPDATE_ACCOUNT_NAME',
    OPEN_LINKED_ACCOUNT = 'OPEN_LINKED_ACCOUNT',
}

export function setLinkedAccountsInfo(linkedAccounts: Array<LinkedAccount>) {
    return {
        type: LinkedAccountsActions.SET_LINKED_ACCOUNTS_INFO,
        payload: linkedAccounts,
    };
}

export function setLinkedAccountsErrorInfo(error: unknown) {
    return {
        type: LinkedAccountsActions.SET_LINKED_ACCOUNTS_ERROR_INFO,
        payload: error,
    };
}

export function updateAccountName(_id: string, accountName: string) {
    return {
        type: LinkedAccountsActions.UPDATE_ACCOUNT_NAME,
        payload: {_id, accountName},
    };
}

export function setOpeningLinkedAccount(_id: string, isOpening: boolean) {
    return {
        type: LinkedAccountsActions.OPEN_LINKED_ACCOUNT,
        payload: {[_id]: isOpening},
    };
}

export function setRowErrors(_id: string, error: boolean) {
    return {
        type: LinkedAccountsActions.SET_ROW_ERRORS,
        payload: {[_id]: error},
    };
}

export function setLinkExpire(_id: string, linkExpire: number) {
    return {
        type: LinkedAccountsActions.SET_LINK_EXPIRE,
        payload: {[_id]: linkExpire},
    };
}

export type LinkedAccountsActionsType =
    | ReturnType<typeof setLinkedAccountsInfo>
    | ReturnType<typeof setLinkedAccountsErrorInfo>
    | ReturnType<typeof updateAccountName>
    | ReturnType<typeof setOpeningLinkedAccount>
    | ReturnType<typeof setLinkExpire>;
