export const RouteConstants = {
    index: '/',
    login: '/login',
    application: '/application',
    accountFederation: '/application/accountFederation',
    userSearch: '/application/userSearch',
    linkedAccounts: '/application/linkedAccounts',
    page500: '/500.html',
    page404: '/404.html',
};
