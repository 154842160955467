export default {
    PAGE_TITLE: 'User Search',
    HELP_TEXT:
        'Search all users under your {amount} federated account(s) by user name, email, number or extension number. Results will display below once the search is complete.',
    NUMBER_SEARCH_TIPS: 'Number Search Tips:',
    NUMBER_SEARCH_TIPS_TEXT:
        'Search <b>international number</b> with country code: e.g. +441686324302, search <b>domestic number</b> without prefix e.g. 1686324302',
    NAME: 'Name',
    EMAIL: 'Email',
    NUMBER: 'Number',
    SITE: 'Site',
    OPEN_ACCOUNT_ERROR: {
        '0':
            'Unable to open {userName}’s details due to a setup error with {accountName}. Contact support via {supportNumber}.',
        '2010':
            'Unable to open {userName}’s details due to a setup error with {accountName}. Please <a class="show-underline" target="_blank" href="{callSupportURL}">contact support</a>.',
        '2110':
            'Unable to open {userName}’s details due to a setup error with {accountName}. Please <a class="show-underline" target="_blank" href="{callSupportURL}">contact support</a>.',
        '2210':
            'Unable to open {userName}’s details due to a setup error with {accountName}. Please <a class="show-underline" target="_blank" href="{callSupportURL}">contact support</a>.',
        '6010':
            'Unable to open {userName}’s details due to a setup error with {accountName}. Please <a class="show-underline" target="_blank" href="{callSupportURL}">contact support</a>.',
        '2020':
            'Unable to open {userName}’s details due to a setup error with {accountName}. Please <a class="show-underline" target="_blank" href="{callSupportURL}">contact support</a>.',
    },
    UNABLE_TO_OPEN_ACCOUNT: 'Unable to Open User Detail',
};
