import {Cookies, EventBus, _, getConfig, translate} from '@ringcentral/web-modules-core';
import {parse as parseQuery} from 'query-string';

import commonLang from '../lang/common/index-en_US';

import {SubScribeCallBackArgs, subscribe} from './loginWindowsNotification';
import {BRAND_COOKIE_NAME} from './contants';
import * as BrandIDs from './BrandIDs';

export function openNewWindow(url: string, windowName = '_blank', width = 770, height = 700) {
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    return window.open(
        url,
        windowName,
        `directories=no, status=no, menubar=yes, scrollbars=yes, resizable=yes, copyhistory=yes, width=${width}, height=${height}, top=${top}, left=${left}`,
    );
}

export const openLoginWindow = (() => {
    let prevWin: Window | null = null;
    let prevUnSubscribe: any = null;

    const OnLoginWindowClosed = 'OnLoginWindowClosed';

    return (url: string): Promise<SubScribeCallBackArgs | null> => {
        if (prevWin) {
            prevWin.close();
            prevUnSubscribe();
            EventBus.emit(OnLoginWindowClosed);
        }
        const newWindow = openNewWindow(url);
        prevWin = newWindow;
        return new Promise((resolve, reject) => {
            EventBus.on(OnLoginWindowClosed, () => {
                resolve(null);
            });

            prevUnSubscribe = subscribe((data) => {
                resolve(data);
                prevWin = null;
            });
        });
    };
})();

export function isDebugMode() {
    return window.localStorage.getItem('IS_MAA_DEBUG') === 'true';
}

export function isCallbackPage() {
    return /callback/.test(window.location.href);
}

export function isIndexPage() {
    return /index.html/.test(window.location.href);
}

export function getUBrandIdImpl(): string {
    const sBrandId: any = parseQuery(window.location.search).s || Cookies.get(BRAND_COOKIE_NAME);

    if (!sBrandId || isNaN(parseInt(sBrandId))) {
        return String(BrandIDs.BRAND_ID_RC);
    }

    return String(sBrandId);
}

export function getBrandIdImpl(): number {
    return parseInt(getUBrandIdImpl());
}

export const getBrandId = _.memoize(getBrandIdImpl);

export const getUBrandId = _.memoize(getUBrandIdImpl);

export function getUiOptions(brandId: number) {
    const uiOptions = 'hide_tos hide_remember_me show_back_to_app';
    const hideLogoBrands = [BrandIDs.BRAND_ID_ATT, BrandIDs.BRAND_ID_ATT_UB];

    if (hideLogoBrands.includes(brandId)) {
        return `${uiOptions} hide_logo`;
    }
    return uiOptions;
}

export function objectToUrlParams(obj: any) {
    if (!obj) {
        return '';
    }
    return Object.keys(obj)
        .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
        .join('&');
}

export const setRoutes = (url: string, data: any) => {
    let urlResult = url;
    Object.keys(data).forEach((key) => {
        urlResult = urlResult.replace(`/:${key}`, `/${data[key]}`);
    });
    return urlResult;
};

export function replaceQueryString(url: string, params: Record<string, string>) {
    const [path, search] = url.split('?');
    const queryString = parseQuery(search);
    Object.keys(params).forEach((key) => {
        queryString[key] = params[key];
    });

    return `${path}?${objectToUrlParams(queryString)}`;
}

export function isLoginPage() {
    return /\/login$/.test(document.location.pathname);
}

export function setDocumentTitle(title: string): void {
    document.title = title;
}

export function searchStringToRegExp(str = '') {
    const trimmedStr = str.trim();
    let escaped;

    if (trimmedStr.length === 0) {
        return null;
    }

    if (/^[-+()\s\d]+$/.test(trimmedStr)) {
        escaped = trimmedStr
            .replace(/[-+()\s]+/g, '')
            .split('')
            .join(')(\\D*?[-\\+\\(\\)\\s]*\\D*?)(');
    } else {
        escaped = trimmedStr.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/\s+/, ')(.*?\\s+.*?)(');
    }

    return new RegExp('(.*?)(' + escaped + ')(.*?)', 'gi');
}

export function getBrandedClassname(classname: string) {
    const {brandId} = getConfig();
    return `${classname}-${brandId}`;
}

export function getLocalizedError(error: any) {
    const commonLoc = commonLang as Record<string, string | object>;

    //api common status error
    if (typeof error === 'string') {
        const errorKey = error.toLocaleUpperCase();
        return translate(commonLoc[errorKey]);
    }

    if (typeof error === 'object') {
        return translate(commonLoc.INTERNAL_ERROR);
    }

    return error;
}

export function objToString(obj: any, visited = new Set<any>()): string {
    if (!obj) {
        return '';
    }

    if (typeof obj === 'string') {
        return obj;
    }
    if (visited.has(obj)) {
        return `recursive object:${String(obj)}`;
    }
    visited.add(obj);

    if (Array.isArray(obj)) {
        return obj.map((val) => objToString(val, visited)).join('|');
    }

    const keys = Object.keys(obj);
    if (keys.length === 0) {
        return String(obj);
    }
    return keys.map((key) => `${key}=${objToString(obj[key], visited)}`).join('|');
}
