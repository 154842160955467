import {
    BRAND_ID_ATT,
    BRAND_ID_ATT_UB,
    BRAND_ID_AU,
    BRAND_ID_BT,
    BRAND_ID_CA,
    BRAND_ID_FEDRAMP,
    BRAND_ID_RC,
    BRAND_ID_RC_EU,
    BRAND_ID_UK,
} from './BrandIDs';

export const BRAND_COOKIE_NAME = 'BRAND';

export enum PagingSorting {
    DESC = 'DESC',
    ASC = 'ASC',
}
export const ON_REQUEST_ERROR = 'On_Request_Error';
export const LOGIN_REQUIRED = 'Login_Required';

export enum Direction {
    ASC = 'ASC',
    DESC = 'DESC',
}

export const BASE_BSS_JSON_FILE_NAME = 'base';

export const notShowTradeMarkBrands = [
    BRAND_ID_RC,
    BRAND_ID_RC_EU,
    BRAND_ID_CA,
    BRAND_ID_UK,
    BRAND_ID_AU,
    BRAND_ID_ATT,
    BRAND_ID_ATT_UB,
    BRAND_ID_FEDRAMP,
    BRAND_ID_BT,
];
