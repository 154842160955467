import {EventBus} from '@ringcentral/web-modules-core';
import {constants as WmcConstants} from '@ringcentral/web-modules-core/es/utils';

export const counterArr: number[] = [];

export function increaseCounter() {
    counterArr.push(1);
    updateRootTestAutomationId(counterArr.length);
}

export function decreaseCounter() {
    counterArr.pop();
    updateRootTestAutomationId(counterArr.length);
}

export function updateRootTestAutomationId(counter: number) {
    const value = counter > 0 ? 'root:pending' : 'root';
    document.body.setAttribute('data-test-automation-id', value);
}

export function initTestAutomation() {
    EventBus.on(WmcConstants.eventBeforeAjaxRequest, increaseCounter);
    EventBus.on(WmcConstants.eventAfterAjaxRequest, decreaseCounter);
}
