import {FederationAccountsState} from '../types';

import {FederationAccountsActions, FederationAccountsActionsType} from './actions';

const federationAccountsInitialState: FederationAccountsState = {
    accounts: [],
};

export function federationAccountsReducers(
    state: FederationAccountsState = federationAccountsInitialState,
    action: FederationAccountsActionsType,
) {
    const {type, payload} = action;

    switch (type) {
        case FederationAccountsActions.SET_FEDERATION_ACCOUNTS_INFO:
            return {
                ...state,
                accounts: payload,
            };
        case FederationAccountsActions.SET_FEDERATION_ACCOUNTS_ERROR_INFO:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
}
