import {EventBus, constants as WmcConstants} from '@ringcentral/web-modules-core';

import {objToString, objectToUrlParams, setRoutes} from '../helpers/utils';
import {SearchUserParams} from '../redux/userSearch/types';
import {ON_REQUEST_ERROR} from '../helpers/contants';

import {ErrorObject} from './types';

export const apiUrls = {
    oauth_ringcentral: '/api/oauth/ringcentral',
    oauth_salesforce: '/api/oauth/salesforce',

    getUser: '/api/user',
    logout: '/api/logout',

    account_links: '/api/account-links',
    account_unlink: '/api/account-links/:ids',
    account_open: '/api/account-links/:id/open',
    account_edit: '/api/account-links/:id',

    service_health: '/api/service-health',
    brandInfo: '/api/brand-info',
    federation_account: '/api/federation/accounts',
    federation_user_query: '/api/federation/users',
    federation_account_open: '/api/federation/users/open',

    logError: '/api/log/error',
    logWarn: '/api/log/warn',
    logInfo: '/api/log/info',
};

export const request = async (url: string, data: any = null, options: any = {skipLoader: false}) => {
    try {
        if (!options.skipLoader) {
            EventBus.emit(WmcConstants.eventBeforeAjaxRequest);
        }

        const headers = new Headers({
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'cache-control': 'no-cache',
            pragma: 'no-cache',
            ...options.headers,
        });
        const reqParams = {
            method: 'GET',
            ...options,
            headers,
        };
        if (reqParams.method !== 'GET') {
            Object.assign(reqParams, {body: JSON.stringify(data)});
        }
        const res = await fetch(url, reqParams);
        const result = await res.json();

        if (result.status && !result.status.success) {
            EventBus.emit(ON_REQUEST_ERROR, {error: result.status.error});
        }

        return result;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return error;
    } finally {
        if (!options.skipLoader) {
            EventBus.emit(WmcConstants.eventAfterAjaxRequest);
        }
    }
};

export const post = (url: string, data?: any, options: any = {}) => request(url, data, {method: 'POST', ...options});
export const del = (url: string, data?: any) => request(url, data, {method: 'DELETE', skipLoader: false});

export const getUser = () => request(apiUrls.getUser);
export const logout = () => request(apiUrls.logout);

export const getAppConfigInfo = (params: any) =>
    request(`${apiUrls.brandInfo}?${objectToUrlParams(params)}`, params, {skipLoader: true});

export const getFederationAccounts = () => request(apiUrls.federation_account);

export const getAccountLinks = (offset: number, limit: number) => {
    const query = objectToUrlParams({offset, limit});
    return request(`${apiUrls.account_links}?${query}`);
};
export const delAccountUnlinks = (ids: string) => del(setRoutes(apiUrls.account_unlink, {ids}));
export const searchFederationAccount = (params: SearchUserParams) => {
    const url = `${apiUrls.federation_user_query}?${objectToUrlParams(params)}`;
    return request(url);
};
export const openAccountLink = (id: string, localeId: string) => {
    const apiUrl = setRoutes(apiUrls.account_open, {id});
    const query = objectToUrlParams({localeId});
    return request(`${apiUrl}?${query}`, null, {skipLoader: true});
};

export const editAccountLink = (id: string, accountName: string) =>
    post(setRoutes(apiUrls.account_edit, {id}), {_id: id, accountName}, {skipLoader: true});

export const accountFederationOpen = (params: any) =>
    request(`${apiUrls.federation_account_open}?${objectToUrlParams(params)}`);

const log = (url: string, data: string) => {
    const headers = new Headers({
        'Content-Type': 'text/plain',
        'X-Requested-With': 'XMLHttpRequest',
        'cache-control': 'no-cache',
        pragma: 'no-cache',
    });
    const reqParams: RequestInit = {
        method: 'POST',
        headers,
        body: data,
    };

    fetch(url, reqParams).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
    });
};

export const logError = (data: ErrorObject) => log(apiUrls.logError, objToString(data));
export const logInfo = (data: ErrorObject) => log(apiUrls.logInfo, objToString(data));
export const logWarn = (data: ErrorObject) => log(apiUrls.logWarn, objToString(data));
