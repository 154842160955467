import 'core-js/stable';
import 'whatwg-fetch';
import {setConfig} from '@ringcentral/web-modules-core';

import {bootstrapApplication} from './bootstrapApplication';
import {getBrandId, isCallbackPage} from './helpers/utils';
import {bootstrapCallback} from './bootstrapCallback';

export async function autoRun() {
    setConfig({brandId: getBrandId()});
    if (isCallbackPage()) {
        bootstrapCallback();
        return;
    }

    await bootstrapApplication();
}

autoRun();
