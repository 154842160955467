import {LinkedAccount, LinkedAccountsState} from '../types';

import {LinkedAccountsActions, LinkedAccountsActionsType} from './actions';

export const linkedAccountsInitialState: LinkedAccountsState = {
    links: [],
    search: '',
    sortField: {},
    pager: {
        currentPage: 0,
        size: 25,
    },
    searchableField: ['accountName', 'extension', 'ownerName', 'formattedNumber'],
    openingAccount: {},
    rowErrors: {},
};

export function linkedAccountsReducers(
    state: LinkedAccountsState = linkedAccountsInitialState,
    action: LinkedAccountsActionsType,
) {
    const {type, payload} = action;

    switch (type) {
        case LinkedAccountsActions.SET_LINKED_ACCOUNTS_INFO:
            return {
                ...state,
                links: payload,
            };
        case LinkedAccountsActions.SET_LINKED_ACCOUNTS_ERROR_INFO:
            return {
                ...state,
                error: payload,
            };
        case LinkedAccountsActions.UPDATE_ACCOUNT_NAME: {
            const {_id, accountName} = payload as LinkedAccount;
            return {
                ...state,
                links: state.links.map((link) => {
                    if (link._id === _id) {
                        return {...link, accountName};
                    }
                    return link;
                }),
            };
        }
        case LinkedAccountsActions.OPEN_LINKED_ACCOUNT:
            return {
                ...state,
                openingAccount: {...state.openingAccount, ...(payload as any)},
            };
        case LinkedAccountsActions.SET_ROW_ERRORS:
            return {
                ...state,
                rowErrors: {...state.rowErrors, ...(payload as any)},
            };
        case LinkedAccountsActions.SET_LINK_EXPIRE: {
            const {_id, linkExpire} = payload as LinkedAccount;
            const newLinks = state.links.map((link) => {
                const newLinkExpire = link._id === _id ? linkExpire : link.linkExpire;

                return {
                    ...link,
                    linkExpire: newLinkExpire,
                };
            });
            return {
                ...state,
                links: newLinks,
            };
        }
        default:
            return state;
    }
}
