import {LoadingStatus, UserState} from '../types';

import {UserActions, UserActionsType} from './actions';

const userInitialState: UserState = {
    user: {},
    loadingStatus: LoadingStatus.INITIAL,
};

export function userReducers(state: UserState = userInitialState, action: UserActionsType) {
    const {type, payload} = action;

    switch (type) {
        case UserActions.SET_USER_INFO:
            return {
                ...state,
                user: payload,
            };
        case UserActions.SET_USER_ERROR_INFO:
            return {
                ...state,
                error: payload,
            };
        case UserActions.SET_LOADING_STATUS_INFO:
            return {
                ...state,
                loadingStatus: payload,
            };
        default:
            return state;
    }
}
