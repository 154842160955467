import {Dispatch} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {getBSSInfo} from '../../data/brandAssetTransport';
import {AppConfigActionsType} from '../appConfig/actions';
import {AppConfigState} from '../types';

import {BrandAssetActionsType, initBrandAsset} from './actions';

export type BrandAssetDataDispatcher = ThunkDispatch<AppConfigState, void, AppConfigActionsType>;

export function fetchBrandAssetData() {
    return async (dispatch: Dispatch<BrandAssetActionsType>) => {
        try {
            const mergedBrandAssetData = await getBSSInfo();
            dispatch(initBrandAsset(mergedBrandAssetData));
            return mergedBrandAssetData;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            return {};
        }
    };
}
