export default {
    PAGE_TITLE: 'Multi-Account Access',
    SALESFORCE: 'Salesforce',
    LOGIN_WITH_SALESFORCE_DESCRIPTION: 'A {brandName} Partner Account',
    SIGN_IN: 'Sign In',
    SIGN_IN_WITH: 'Or Sign In With',
    HAVE_BRAND_ACCOUNT: {
        '0': 'Have a {brandName} Account?',
        '3420': 'Have an {brandName} Account?',
        '6010': 'Have an {brandName} Account?',
    },
};
