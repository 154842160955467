export default {
    PAGE_TITLE: 'Manage Linked Accounts',
    MANAGE_ALL_ACCOUNT_DESCRIPTION:
        'To easily access and manage multiple accounts, link accounts using one-time authorization.',
    MANAGE_ALL_ACCOUNT_DESCRIPTION_FOR_SALESFORCE_USER: 'Manage all the accounts that you have access to.',
    ACCOUNT_NAME: 'Account Name',
    MAIN_NUMBER: 'Main Number',
    OWNER: 'Owner',
    EXTENSION: 'Ext.',
    SERVICE_STATUS: 'Service Status',
    LINK_ACCOUNT: 'Link Account',
    ADD_LINK_ACCOUNT: 'Add Account',
    UNLINK_SELECTED: 'Unlink Selected Account',
    UNLINK_THIS_ACCOUNT_TIP: 'unlink this account',
    EDIT_ACCOUNT_TITLE: 'Edit Account',
    DOUBLE_CLICK_TO_EDIT_ACCOUNT_TITLE: 'Double click to edit account name',
    ARE_YOU_SURE_TO_UNLINK: 'Do you want to unlink "{extensionNames}"?',
    LAST_REFRESH_ON: 'Last refreshed on ',
    ACCOUNT_PROBLEM_DESCRIPTION: 'There is a problem of authorising this account. Please login to fix the issue.',
    SERVICE_STATUS_ITEMS: {
        inboundCall: true,
        outboundCall: true,
        registration: true,
        spRegistration: true,
        serviceWeb: true,
        inboundFax: true,
        outboundFax: true,
        inboundSms: true,
        outboundSMS: true,
    },
    SERVICE_STATUS_NAMES: {
        FILE_DOWNLOAD: 'File Download',
        EMERGENCY_CALLS: 'Emergency Calls',
        MOBILE: 'Mobile',
        OUTBOUND_FAX: 'Outbound Fax',
        SERVICE_WEB: {
            '0': 'service.ringcentral.com',
            '6010': 'service.cloudoffice.avaya.com',
        },
        OUTBOUND_SMS: 'Outbound SMS',
        WEB_SITE: 'WebSite',
        INBOUND_SMS: 'Inbound SMS',
        INBOUND_FAX: 'Inbound Fax',
        SP_REGISTRATION: {
            '0': 'RingCentral Phone desktop app',
            '3420': 'Office@Hand Desktop App',
            '6010': 'Avaya Cloud Phone desktop app',
            '7310': 'Business Connect Desktop App',
            '7710': 'BT Cloud Work Desktop App',
        },
        VOICE_MAIL: 'Voice Mail',
        INBOUND_CALL: 'Inbound Call',
        OUTBOUND_CALL: 'Outbound Call',
        REGISTRATION: 'Desk Phones',
        MEETINGS: 'Meetings',
        CONFERENCING: 'Conferencing',
    },
    LOADING_STATUS: 'Loading services status ...',
    SERVICE_STATUS_UNAVAILABLE: 'The Service Status is temporarily unavailable, please retry later.',
    ACCOUNT_LINK_ERROR: {
        user_cancelled: 'Account link failed.',
    },
};
